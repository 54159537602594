import React, { useEffect, useState } from "react";
import "lazysizes";
import Link from "next/link";
import { array } from "prop-types";
import BlogItem from "../Blog/BlogItem";
import { getImageUrl, getBlogs } from "src/utils/magnolia";

type BlogOverviewProps = {
  title?: string;
  showPageDots: boolean;
  showShowAllButton?: boolean;
  sectionTitle?: string;
  sectionTitleFontSize?: string;
  sectionTitleFontSizeMobile?: string;
  sectionTitlePosition?: string;
  sectionTitleMargin?: string;
  sectionTitleMarginMobile?: string;
};

const BlogOverview = ({
  title = "",
  showShowAllButton = true,
  sectionTitle,
  sectionTitlePosition,
  sectionTitleFontSize,
  sectionTitleFontSizeMobile,
  sectionTitleMargin,
  sectionTitleMarginMobile,
}: BlogOverviewProps) => {
  const [blogs, setBlogs] = useState<Array<any>>([]);
  const [isLoadingOverview, setIsLoadingOverview] = useState(true);

  const getBlogList = async () => {
    const { data: blogs, status } = await getBlogs({
      params: {
        limit: 5,
      },
    });

    if (status == 200) {
      setBlogs(blogs.results);
      setIsLoadingOverview(false);
    }
  };

  useEffect(() => {
    getBlogList();
  }, []);

  return (
    <div className="blog-wrapper">
      {isLoadingOverview ? (
        <div />
      ) : (
        <div className="blog-container">
          {sectionTitle && (
            <div>
              <h2 className="blog-title">{sectionTitle}</h2>
            </div>
          )}
          <div className="blog-carousel-container">
            <div className="large-thumbnails">
              {blogs.length > 0 &&
                blogs.slice(0, 2).map((data, key) => {
                  if (data.title !== title)
                    return (
                      <BlogItem
                        key={key}
                        thumbnail={getImageUrl(data.thumbnail)}
                        title={data.title}
                        excerpt={data.excerpt}
                        slug={data.slug}
                        taxonomy={data.taxonomy}
                      />
                    );
                })}
            </div>
            <div className="small-thumbnails">
              {blogs.length > 0 &&
                blogs.slice(2, 5).map((data, key) => {
                  if (data.title !== title)
                    return (
                      <BlogItem
                        key={key}
                        thumbnail={getImageUrl(data.thumbnail)}
                        title={data.title}
                        excerpt={data.excerpt}
                        slug={data.slug}
                        taxonomy={data.taxonomy}
                        landscape={true}
                      />
                    );
                })}
            </div>
          </div>

          {showShowAllButton && (
            <Link href="/blog" as="/blog" legacyBehavior>
              <a>
                <div className="blog-redirect-button">Lihat Semua Artikel</div>
              </a>
            </Link>
          )}
        </div>
      )}
      <style jsx>{`
        .blog-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 64px;
        }
        .blog-container {
          max-width: 940px;
          width: 100%;
        }

        .blog-carousel-container {
          display: flex;
          align-items: flex-start;
          // min-height: 350px;
        }
        .large-thumbnails {
          display: flex;
        }
        .small-thumbnails {
          display: flex;
          flex-direction: column;
        }
        .blog-carousel-container-mobile {
          display: none;
        }
        .blog-title {
          font-weight: bold;
          margin: ${sectionTitleMargin ? sectionTitleMargin : "0"};
          font-size: ${sectionTitleFontSize ? sectionTitleFontSize : "34px"};
          text-align: ${sectionTitlePosition ? sectionTitlePosition : "center"};
          margin-bottom: 20px;
        }
        .blog-sub-title {
          font-size: 21px;
          text-align: center;
        }
        .blog-redirect-button {
          color: var(--color-red);
          font-weight: bold;
          font-size: 15px;
          text-align: right;
          margin: 0 24px 16px;
        }
        .blog-redirect-button:hover {
          cursor: pointer;
        }
        .prev-button,
        .next-button {
          background-color: transparent;
          border: none;
          padding: 0;
          outline: inherit;
          cursor: pointer;
          top: 50%;
          width: auto;
          margin-top: 385px;
          padding: 16px;
          color: var(--color-white);
          font-weight: bold;
          font-size: 18px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          user-select: none;
        }
        @media only screen and (max-width: 600px) {
          .blog-title {
            font-size: ${sectionTitleFontSizeMobile
              ? sectionTitleFontSizeMobile
              : ""};
            margin: ${sectionTitleMarginMobile ? sectionTitleMarginMobile : ""};
          }
          .blog-carousel-container {
            display: flex;
            flex-direction: column;
          }
          .large-thumbnails {
            flex-direction: column;
          }
          .blog-redirect-button {
            font-size: 12px;
          }

          .blog-card-container {
            width: 80vw;
            height: 56vh; /* overcome homepage blog overview anomali) */
            max-height: 450px;
            max-width: 360px;
            min-height: 410px;
          }
        }
        @media only screen and (max-width: 660px) {
          .large-thumbnails {
            flex-direction: column;
          }
          .blog-wrapper {
            margin-top: 20px;
          }
        }
        @media only screen and (max-width: 990px) {
          .blog-carousel-container {
            flex-direction: column;
            align-items: center;
          }
        }
      `}</style>
    </div>
  );
};

BlogOverview.propTypes = {
  blogSnippets: array,
};

export default BlogOverview;
