import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import UseWindowSize from '../UseWindowSize'
import { SliderBannerMobile } from '../UserInterface/SliderBannerMobile'
import { Placeholder } from '../UserInterface/Placeholder'
import { containPromoHref, getImageUrl } from 'src/utils/magnolia'
import SkeletonImage from '../UserInterface/Skeleton/SkeletonImage'
import { Link as MagnoliaLink } from 'src/components/Magnolia'
import { getMagnoliaBanner } from 'src/utils/gateway'

type HeroBanner = {
  codename?: string
}

const BannerImage = ({ image, url = '', alt }) => {
  const isPromoHref = containPromoHref(url)
  const ComponentLink = isPromoHref ? MagnoliaLink : Link
  return (
    <ComponentLink href={url} legacyBehavior>
      <a style={{ cursor: url ? 'pointer' : 'default' }} href={url}>
        <SkeletonImage src={image} alt={alt} />
      </a>
    </ComponentLink>
  )
}

const HeroBanner = (props: HeroBanner) => {
  const { codename } = props
  const { width } = UseWindowSize()
  const [data, setData] = useState(null)
  const [winSize, setWinSize] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getBanner = async () => {
    const response = await getMagnoliaBanner(codename)
    if (!response.error) {
      setData(response)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getBanner()
    setWinSize(width)
  }, [])

  if (winSize === null) return null
  const isDesktop = width > 600
  return (
    <div className={`banner-${isDesktop ? 'desktop' : 'mobile'}`}>
      {isLoading ? (
        <Placeholder />
      ) : (
        <SliderBannerMobile>
          {data?.map((el, key) => {
            const items = isDesktop
              ? {
                  image: getImageUrl(el.bannerDesktop),
                  url: el.slugDesktop && el.slugDesktop
                }
              : {
                  image: getImageUrl(el.bannerMobile),
                  url: el.slugMobile && el.slugMobile
                }
            return <BannerImage key={key} alt={el.name} {...items} />
          })}
        </SliderBannerMobile>
      )}
      <style jsx>{`
        .banner-mobile {
          background-color: var(--bg-color-img);
        }
        .banner-desktop {
          background-color: var(--bg-color-img);
          min-height: 44vh;
        }
        .prev,
        .next {
          background: none;
          border: none;
          padding: 0;
          outline: inherit;
          cursor: pointer;
          position: absolute;
          top: 50%;
          width: auto;
          margin-top: -25px;
          padding: 16px;
          color: var(--color-white);
          font-weight: bold;
          font-size: 18px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          user-select: none;
        }
        .next {
          right: 0;
          border-radius: 3px 0 0 3px;
        }
        .prev:hover,
        .next:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
        @media only screen and (max-width: 600px) {
          .banner-mobile {
            display: block;
            min-height: 5vh;
          }

          .prev,
          .next {
            padding: 8px;
          }
        }

        @media only screen and (width: 768px) {
          .banner-desktop {
            min-height: 12vh;
          }
        }

        @media only screen and (width: 1024px) {
          .banner-desktop {
            min-height: 15vh;
          }
        }

        @media only screen and (width: 1200px) {
          .banner-desktop {
            min-height: 19vh;
          }
        }

        @media only screen and (width: 1440px) {
          .banner-desktop {
            min-height: 24vh;
          }
        }
      `}</style>
    </div>
  )
}

export default HeroBanner
