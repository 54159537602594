import Link from "next/link";
import React, { FC, useEffect, useState } from "react";
import SkeletonImage from "src/components/UserInterface/Skeleton/SkeletonImage";
import { getMagnoliaBanner } from "src/utils/gateway";
import { getImageUrl } from "src/utils/magnolia";
import UseWindowSize from "src/components/UseWindowSize";

type MonetizationBannerProps = {
  containerMargin?: string[];
  titleFontSize?: string[];
  titleMargin?: string[];
  bannerRadius?: string[];
  titleTextAlign?: string;
  gap?: string[];
};

export const MonetizationBanner: FC<MonetizationBannerProps> = ({
  containerMargin = ["auto", "auto"],
  titleFontSize = ["20px", "34px"],
  titleMargin = ["unset", "unset"],
  bannerRadius = "16px",
  titleTextAlign = "center",
  gap = ["16px", "24px"]
}) => {
  const [dataBanner, setDataBanner] = useState({ slug: "", src: "" });

  const { width } = UseWindowSize();

  const getBanner = async () => {
    const data = await getMagnoliaBanner('/HCIFormulir')
    setDataBanner({
      slug: data[0].slugDesktop,
      src: width > 600 ? getImageUrl(data[0].bannerDesktop) : getImageUrl(data[0].bannerMobile)
    })
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <div className="monetization-banner-container">
        <div className="monetization-banner-title">Penawaran Kami</div>
        <Link href={dataBanner.slug} legacyBehavior>
          <div className="monetization-banner-img">
            <SkeletonImage
              height={dataBanner.src ? "auto" : "250px"}
              src={dataBanner.src}
              alt="Monetization Banner"
            />
          </div>
        </Link>
      </div>
      <style jsx>{`
        .monetization-banner-container {
          display: flex;
          flex-direction: column;
          gap: ${gap[0]};
          // margin: 16px 16px 0;
          margin: ${containerMargin[0]};
        }

        .monetization-banner-title {
          font-family: Roboto;
          font-size: ${titleFontSize[0]};
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -0.1599999964237213px;
          text-align: ${titleTextAlign};
          margin: ${titleMargin[0]};
        }

        .monetization-banner-img {
          display: flex;
          width: 100%;
          height: auto;
          cursor: pointer;
          border-radius: ${bannerRadius[0]};
        }

        @media only screen and (min-width: 600px) {
          .monetization-banner-container {
            display: flex;
            flex-direction: column;
            gap: ${gap[1]};
            margin: 48px auto 0;
            margin: ${containerMargin[1]};
          }

          .monetization-banner-title {
            font-family: Roboto;
            font-size: ${titleFontSize[1]};
            font-weight: 700;
            line-height: 48px;
            letter-spacing: -0.3199999928474426px;
            text-align: ${titleTextAlign};
            margin: ${titleMargin[1]};
          }

          .monetization-banner-img {
            width: 940px;
            border-radius: ${bannerRadius[1]};
          }
        }
      `}</style>
    </React.Fragment>
  );
};
